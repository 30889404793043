// import { sampleHtmlString } from "./sampleHtmlString";

const parser = new DOMParser();
const classesToParse = {
  goingRate: "goingRate", // The prices currently listed, including yours and theirs
  inventoryCardData: "inventoryCardData", // Has the card title, id, and link to where you can edit price
};
const skippableRates = {
  monopoly: "Monopoly",
  noneForSale: "none for sale",
};
const keywordsForRates = {
  at: "at",
  from: "from",
};

export enum RateStatus {
  Unknown = "Unknown",
  SkippedMonopoly = "Skipped-Monopoly",
  SkippedNoneForSale = "Skipped-NoneForSale",
  Calculated = "Calculated",
}

export interface ICardData {
  cardTitle: string;
  cardHyperlink: string;
  youPriceStatus: RateStatus;
  therePriceStatus: RateStatus;
  youMinPrice: number;
  thereMinPrice: number;
}

export const parseHtmlForScalping = (htmlString: string): ICardData[] => {
  console.log(`Begin parsing HTML`);
  //   const parsedHtml = parser.parseFromString(sampleHtmlString, "text/html");
  const parsedHtml = parser.parseFromString(htmlString, "text/html");

  const rateNodes = parsedHtml.getElementsByClassName(classesToParse.goingRate);
  const inventoryNodes = parsedHtml.getElementsByClassName(
    classesToParse.inventoryCardData
  );

  const cardData: ICardData[] = [];
  for (let i = 0; i < inventoryNodes.length; i++) {
    const rateNode = rateNodes[i];
    const inventoryNode = inventoryNodes[i];

    // Get card title
    const inventoryText = (inventoryNode as any).innerText.replace(
      /[\W_]+/g,
      " "
    );
    const messyCardTitle = inventoryText.split("wrong")[0];
    const cardTitle = messyCardTitle.slice(1, messyCardTitle.length - 1);

    // Get card id, convert to hyperlink
    let secondaryInventoryKeyword = "Added";
    if (inventoryText.includes("Acquired")) {
      secondaryInventoryKeyword = "Acquired";
    }
    const cardIdMessy = inventoryText.substring(
      inventoryText.lastIndexOf("Item") + 1,
      inventoryText.lastIndexOf(secondaryInventoryKeyword)
    );
    const cardId = cardIdMessy.slice(4, cardIdMessy.length - 1);
    const cardHyperlink = `https://www.comc.com/item/${cardId}`;

    // *********
    // Calculate going rates
    // *********
    const rateText: string = (rateNode as any).innerText.replace(
      /[\W_]+/g,
      " "
    );

    let youPriceStatus: RateStatus = RateStatus.Unknown;
    let therePriceStatus: RateStatus = RateStatus.Unknown;
    let youMinPrice: number = 0;
    let thereMinPrice: number = 0;

    // Skip rate calculation if skippable terms found
    if (rateText.includes(skippableRates.monopoly)) {
      youPriceStatus = RateStatus.SkippedMonopoly;
      therePriceStatus = RateStatus.SkippedMonopoly;
    } else if (rateText.includes(skippableRates.noneForSale)) {
      youPriceStatus = RateStatus.SkippedNoneForSale;
      therePriceStatus = RateStatus.SkippedNoneForSale;
    } else {
      // Split sentence into 2 parts, "you" and "there" parts
      const [youText, thereText] = rateText.split("There");

      // First do "you" part
      youMinPrice = extractMinPriceFromRateTextPart(youText);

      // Then do "there" part
      thereMinPrice = extractMinPriceFromRateTextPart(thereText);

      youPriceStatus = RateStatus.Calculated;
      therePriceStatus = RateStatus.Calculated;
    }

    // Log stuff
    console.log(i + ` =============== ` + i);
    console.log("card-title:", cardTitle);
    console.log("card-hyperlink:", cardHyperlink);
    console.log("you-status:", youPriceStatus);
    console.log("there-status:", therePriceStatus);
    console.log("you-price:", youMinPrice);
    console.log("there-price:", thereMinPrice);

    cardData.push({
      cardTitle,
      cardHyperlink,
      youPriceStatus,
      therePriceStatus,
      youMinPrice,
      thereMinPrice,
    });
  }

  console.log(`Finish parsing HTML`);
  return cardData;
};

const extractMinPriceFromRateTextPart = (rateTextPart: string): number => {
  let minPrice = "!?!";

  if (rateTextPart.indexOf(keywordsForRates.at) === -1) {
    // Do logic for "from"
    minPrice = rateTextPart.slice(rateTextPart.indexOf("from")).slice(5, 9);
  } else {
    // Do logic for "at"
    minPrice = rateTextPart.slice(rateTextPart.indexOf("at")).slice(3, 7);
  }

  return parseFloat(minPrice.replace(" ", "."));
};
