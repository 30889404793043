import React from "react";
import { Label, List, Segment } from "semantic-ui-react";
import { ICardData } from "../parsingUtil";
import { CardListItem } from "./CardListItem";

interface ICardListProps {
  cardData: ICardData[];
  countNeedsScalping: number;
  countNoNeed: number;
  countSkippedMonopoly: number;
  countSkippedNoneForSale: number;
}

export const CardList: React.FunctionComponent<ICardListProps> = (props) => {
  const {
    cardData,
    countNeedsScalping,
    countNoNeed,
    countSkippedMonopoly,
    countSkippedNoneForSale,
  } = props;
  const countTotalCards = cardData.length;

  return (
    <div style={{ paddingLeft: "2rem", paddingRight: "2rem" }}>
      <Segment secondary>
        <Label
          basic
          content={`Total cards: ${cardData.length}`}
          color={"black"}
        />
        <Label
          basic
          icon={"exclamation triangle"}
          content={`Needs scalping: ${countNeedsScalping} | ${(
            (countNeedsScalping / countTotalCards) *
            100
          ).toFixed(1)}%`}
        />
        <Label
          basic
          content={`Skipped due to Monopoly: ${countSkippedMonopoly} | ${(
            (countSkippedMonopoly / countTotalCards) *
            100
          ).toFixed(1)}%`}
        />
        <Label
          basic
          content={`Skipped due to none for sale: ${countSkippedNoneForSale} | ${(
            (countSkippedNoneForSale / countTotalCards) *
            100
          ).toFixed(1)}%`}
        />
        <Label
          basic
          content={`No attention required: ${countNoNeed} | ${(
            (countNoNeed / countTotalCards) *
            100
          ).toFixed(1)}%`}
        />
      </Segment>
      <List divided relaxed>
        {cardData.map((card, index) => {
          return <CardListItem key={`CardListItem-${index}`} card={card} />;
        })}
      </List>
    </div>
  );
};
