import React, { useState, useRef } from "react";
import { CardList } from "./components/CardList";
import { HomeMenu } from "./components/HomeMenu";
import { ICardData, RateStatus } from "./parsingUtil";

const App: React.FunctionComponent = (props) => {
  let contextRef = useRef(null);
  const [cardData, setCardData] = useState<ICardData[]>([]);
  const needsScalpingCardData: ICardData[] = [];
  const noNeedCardData: ICardData[] = [];
  const skippedMonopolyCardData: ICardData[] = [];
  const skippedNoneForSaleData: ICardData[] = [];
  cardData.forEach((card) => {
    if (card.youPriceStatus === RateStatus.SkippedMonopoly) {
      skippedMonopolyCardData.push(card);
    } else if (card.therePriceStatus === RateStatus.SkippedNoneForSale) {
      skippedNoneForSaleData.push(card);
    } else if (card.thereMinPrice < card.youMinPrice) {
      needsScalpingCardData.push(card);
    } else {
      noNeedCardData.push(card);
    }
  });

  const sortedCardData = needsScalpingCardData
    .concat(skippedMonopolyCardData)
    .concat(skippedNoneForSaleData)
    .concat(noNeedCardData);
  const allNeededTabs = needsScalpingCardData.map((card) => card.cardHyperlink);

  return (
    <div ref={contextRef}>
      <HomeMenu
        setCardData={setCardData}
        allNeededTabs={allNeededTabs}
        contextRef={contextRef}
      />
      <CardList
        cardData={sortedCardData}
        countNeedsScalping={needsScalpingCardData.length}
        countNoNeed={noNeedCardData.length}
        countSkippedMonopoly={skippedMonopolyCardData.length}
        countSkippedNoneForSale={skippedNoneForSaleData.length}
      />
    </div>
  );
};

export default App;
