import React from "react";
import { Icon, Label, List } from "semantic-ui-react";
import { ICardData, RateStatus } from "../parsingUtil";

interface ICardListItemProps {
  card: ICardData;
}

export const CardListItem: React.FunctionComponent<ICardListItemProps> = (
  props
) => {
  const { card } = props;
  const {
    cardTitle,
    cardHyperlink,
    youPriceStatus,
    youMinPrice,
    thereMinPrice,
  } = card;

  let isInNeedOfScalping = false;
  let priceDifferential = 0;
  if (thereMinPrice < youMinPrice) {
    isInNeedOfScalping = true;
    priceDifferential = youMinPrice - thereMinPrice;
  }

  let priceContentToRender;

  if (youPriceStatus === RateStatus.Calculated) {
    priceContentToRender = (
      <>
        <Icon
          size={"big"}
          name={isInNeedOfScalping ? "exclamation triangle" : "check"}
          color={isInNeedOfScalping ? "red" : "grey"}
        />
        <Label color="teal">{`You: $${youMinPrice.toFixed(2)}`}</Label>
        <Label color="orange">{`Them: $${thereMinPrice.toFixed(2)}`}</Label>
        {isInNeedOfScalping && (
          <Label
            basic
            size={"small"}
            content={`-$${priceDifferential.toFixed(2)}`}
          />
        )}
      </>
    );
  } else if (
    youPriceStatus === RateStatus.SkippedMonopoly ||
    youPriceStatus === RateStatus.SkippedNoneForSale
  ) {
    const stringToRender =
      youPriceStatus === RateStatus.SkippedMonopoly
        ? "Monopoly"
        : "None for sale";
    priceContentToRender = <Label color="black">{stringToRender}</Label>;
  }

  return (
    <List.Item>
      <List.Header>{cardTitle}</List.Header>
      <List.Content>
        <a href={cardHyperlink} target="_blank" rel="noreferrer">
          <List.Icon name="chain" />
          {cardHyperlink}
        </a>
        <List.List>{priceContentToRender}</List.List>
      </List.Content>
    </List.Item>
  );
};
