import React from "react";
import { useState } from "react";
import {
  Button,
  Input,
  InputOnChangeData,
  Menu,
  Sticky,
} from "semantic-ui-react";
import { ICardData, parseHtmlForScalping } from "../parsingUtil";

interface IHomeMenuProps {
  setCardData: (cardData: ICardData[]) => void;
  allNeededTabs: string[];
  contextRef: React.MutableRefObject<null>;
}

export const HomeMenu: React.FunctionComponent<IHomeMenuProps> = (props) => {
  const { setCardData, allNeededTabs, contextRef } = props;
  const [htmlString, setHtmlString] = useState<string>("");
  const onHtmlStringChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => {
    setHtmlString(data.value);
  };
  const temporaryHardCodedParse = () => {
    setCardData(parseHtmlForScalping(htmlString));
  };
  const openAllScalpNeededTabs = () => {
    allNeededTabs.forEach((neededUrl, index) => {
      setTimeout(() => {
        window.open(neededUrl, `${index}`);
      }, index * 100);
    });
  };
  return (
    <Sticky context={contextRef}>
      <Menu>
        <Menu.Item>
          <Button onClick={temporaryHardCodedParse} color={"green"}>
            {"Parse"}
          </Button>
        </Menu.Item>
        <Menu.Item>
          <Input
            placeholder={"Paste HTML here..."}
            value={htmlString}
            onChange={onHtmlStringChange}
          />
        </Menu.Item>
        <Menu.Item>
          <Button onClick={openAllScalpNeededTabs} basic>
            {"Open tabs for all needed cards"}
          </Button>
        </Menu.Item>
      </Menu>
    </Sticky>
  );
};
